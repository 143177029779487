Ember.TEMPLATES["javascripts/connectors/user-custom-preferences/signature-preferences"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1,[\"signatures_enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group signatures\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"signatures.enable_signatures\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"type\",\"checked\"],[\"checkbox\",[33,4,[\"see_signatures\"]]]]]],[1,\"\\n        \"],[1,[28,[35,2],[\"signatures.show_signatures\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"control-group signatures\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"signatures.my_signature\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls bio-composer input-xxlarge\"],[12],[1,\"\\n\"],[41,[33,1,[\"signatures_advanced_mode\"]],[[[1,\"        \"],[1,[28,[35,5],null,[[\"value\",\"showUploadModal\"],[[33,4,[\"custom_fields\",\"signature_raw\"]],\"showUploadModal\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"label\"],[14,0,\"text-label\"],[12],[1,\"\\n          \"],[1,[28,[35,3],null,[[\"type\",\"class\",\"value\"],[\"text\",\"input-xxlarge\",[33,4,[\"custom_fields\",\"signature_url\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"siteSettings\",\"i18n\",\"input\",\"model\",\"d-editor\"]]","moduleName":"javascripts/discourse/templates/connectors/user-custom-preferences/signature-preferences","isStrictMode":false});
define("discourse/plugins/discourse-signatures/discourse/initializers/extend-for-signatures", ["exports", "discourse/lib/plugin-api", "discourse/widgets/raw-html", "@ember/utils"], function (_exports, _pluginApi, _rawHtml, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function attachSignature(api, siteSettings) {
    api.includePostAttributes("user_signature");
    api.decorateWidget("post-contents:after-cooked", dec => {
      const attrs = dec.attrs;

      if ((0, _utils.isEmpty)(attrs.user_signature)) {
        return;
      }

      const currentUser = api.getCurrentUser();
      let enabled;

      if (currentUser) {
        var _currentUser$get;

        enabled = (_currentUser$get = currentUser.get("custom_fields.see_signatures")) !== null && _currentUser$get !== void 0 ? _currentUser$get : siteSettings.signatures_visible_by_default;
      } else {
        enabled = siteSettings.signatures_visible_by_default;
      }

      if (enabled) {
        if (siteSettings.signatures_advanced_mode) {
          return [dec.h("hr"), dec.h("div", new _rawHtml.default({
            html: "<div class='user-signature'>".concat(attrs.user_signature, "</div>")
          }))];
        } else {
          return [dec.h("hr"), dec.h("img.signature-img", {
            attributes: {
              src: attrs.user_signature
            }
          })];
        }
      }
    });
  }

  function addSetting(api) {
    api.modifyClass("controller:preferences/profile", {
      pluginId: "discourse-signatures",
      actions: {
        save() {
          this.set("model.custom_fields.see_signatures", this.get("model.see_signatures"));
          this.get("saveAttrNames").push("custom_fields");

          this._super();
        }

      }
    });
  }

  var _default = {
    name: "extend-for-signatures",

    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");

      if (siteSettings.signatures_enabled) {
        (0, _pluginApi.withPluginApi)("0.1", api => attachSignature(api, siteSettings));
        (0, _pluginApi.withPluginApi)("0.1", api => addSetting(api, siteSettings));
      }
    }

  };
  _exports.default = _default;
});

